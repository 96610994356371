/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: poppins;
  src: url(assets/fonts/Poppins-Bold.ttf);
}

html,
body {
  padding: 0;
  margin: 0;
  background: #fff;
}

.cont {
  display: table;
  width: 100px;
}

.image-background {
  //background-image: url("assets/img/Jujuy.jpg");

  background-position: center center;

  /* Image doesn't repeat */
  //background-repeat: no-repeat;

  /* Makes the image fixed in the viewport so that it doesn't move when
     the content height is greater than the image height */
  //background-attachment: fixed;

  /* This is what makes the background image rescale based on its container's size */
  //background-size: cover;

  /* Pick a solid background color that will be displayed while the background image is loading */
  background-color: #dadada;

  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  background-blend-mode: hard-light;
}
.overlay {
  position: relative;
  min-height: 100%;
  min-width: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
}
.focused-content {
  margin-top: 70px;
  padding: 5px 0;
  min-height: calc(100vh - 123px);
  margin: 0 auto;
  width: 93%;
}
.focused-login {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -55%);
}
.btn-actions {
  padding-top: 10px;
}
.focus-text {
  text-align: center;
}
.custom-table-insside {
  padding: 30px 0;
}
.align {
  text-align: -webkit-center;
}
.text-azul{
  color: #2995cc;
}
.bg-azul{
  background-color: #2995cc;
}
.bg-azul-light{
  background-color: #eef7fa;
}
.text-rojo{
  color: #fa503f;
}
.bg-rojo{
  background-color: #fa503f;
}
.title-boxes{
  font-size: 17px;
  i{
    font-size: 10px !important;
    color: #0098c8 !important;
    margin-right: 6px !important;
  }
}

.card-img-adn{
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  height: 233px;
  cursor: pointer;

  h5{
    position: absolute;
    bottom: 0;
    color: white;
    font-size: 15px;
    font-weight: 400;
    padding-left: 15px;
    padding-right: 15px;
  }
  .activity {
    position: absolute;
    top: 5px;
    color: white;
    font-size: 15px;
    font-weight: 400;
    padding-left: 6px;
    padding-right: 15px;
    text-shadow: 0px 1px 16px rgba(0,0,0,0.64);
  }
  img{
    margin-right: 7px;
    background: #ffffffc4;
    width: 30px;
    height: 30px;
    padding: 4px;
    border-radius: 50%;
  }
}
.overlay-card-adn {
  position: relative;
  min-height: 100%;
  min-width: 100%;
  left: 0;
  top: 0;
  background: rgb(0,0,0);
  background: linear-gradient(360deg, rgba(0,0,0,0.5046393557422969) 0%, rgba(0,0,0,0) 32%);
  border-radius: 15px;
}
.overlay-card-adn-x2 {
  position: relative;
  min-height: 100%;
  min-width: 100%;
  left: 0;
  top: 0;
  background: rgb(0,0,0);
  background: linear-gradient(360deg, rgba(0,0,0,0.8547794117647058) 0%, rgba(0,0,0,0) 44%);
  border-radius: 15px;
}
.mat-form-field-underline {
  width: 90%;
}

.carousel-indicators li{
  background-color: #ff4143 !important;
  width: 10px !important;
}
.carousel-indicadores{
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-indicators {
  margin-bottom: -1.7rem !important;
}
.carousel-control-next, .carousel-control-prev {
  width: 24px !important;
}



.card-img-adn-result{
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px 10px 0 0;
  height: 165px;
  cursor: pointer;
}
.title-result-destino{
  background-color: #0098c8;
  color: white;
  .card-title{
    font-size: 14px;
  }
}

.title-result-desde{
  background-color: #eef7fa;
  color: #fa503f;
  .card-title{
    font-size: 14px;
  }
}

.title-result-detalles{
  background-color: #fbfbfb;
  color: #515151;
  .card-title{
    font-size: 14px;
  }
}
.bottom-radius{
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}


.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #fa503f;
  color: #fff;
}

.mat-step-label {
  min-width: 100%;
  text-transform: uppercase;
  font-size: 12px;
}
.mat-horizontal-content-container {
  padding: 15px 15px 24px 15px !important;

}

.mat-stepper-horizontal, .mat-stepper-vertical {
  min-height: 73vh;
  .card{
    border-radius: 13px !important;
  }
}

.mat-accordion .mat-expansion-panel:last-of-type {
  border-radius: 13px !important;
}


.mat-horizontal-stepper-header-container {
  background: white !important;
}

.iconos-pago{
  width: 240px;
  margin-left: 15px;
}


.carousel-control-next, .carousel-control-prev {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: black !important;
  top: calc(50% - 22px)!important;
  bottom: auto;
  margin-left: -15px !important;
  margin-right: -15px !important;
  width: 18px !important;
  opacity: 0.3 !important;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  width: 10px !important;
  /*background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='red' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;*/

}

@media only screen and (max-width: 391px) {
  .carousel-control-next,
  .carousel-control-prev{
    background-color: transparent !important;
    filter: invert(100%);
  }
  .carousel-control-next-icon, .carousel-control-prev-icon {
    color: black !important;
  }

}

@media only screen and (max-width: 769px) and (min-width: 391px) {
  .carousel-control-next,
  .carousel-control-prev{
    background-color: transparent !important;
    filter: invert(100%);
  }
  .carousel-control-next-icon, .carousel-control-prev-icon {
    color: black !important;
  }

}

@media only screen and (max-width: 575px) and (min-width: 391px) {
  .carousel-control-next,
  .carousel-control-prev{
    background-color: transparent !important;
    filter: invert(100%);
  }
  .carousel-control-next-icon, .carousel-control-prev-icon {
    color: black !important;
  }
}


.sinresultados{
  border: 1px solid #dbdbdb;
  padding: 18px;
  border-radius: 15px;
  text-align: center;
}

.cdk-overlay-pane {
  overflow: auto;

  max-width: 90% !important;
 /* width: 100% !important;
  height: 100vh !important;*/
}
